import React, { useState } from "react";
import { useParams } from "react-router-dom";
import data from "./data.json";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { Heart, Copy, Download, ExternalLink } from "lucide-react";

const PreviewPage = () => {
  const { id } = useParams();
  const itemIndex = data.findIndex((dataItem) => dataItem.id === id);
  const item = data[itemIndex];

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [downloads, setDownloadCount] = useState(item?.downloads || 0);

  // Define the RiveComponent regardless of the item state to follow React hook rules
  const { RiveComponent } = useRive({
    src: item?.riveData?.riveFile || "",
    stateMachines: item?.riveData?.stateName || "",
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: item?.riveData?.autoplay || false,
  });

  // Handle the case where the item is not found
  if (!item) {
    return (
      <div className="container mx-auto p-4 text-center">
        <h2 className="text-white text-2xl font-bold mb-2">Item not found</h2>
        <p className="text-gray-400">
          The item you're looking for does not exist or has been removed.
        </p>
      </div>
    );
  }

  // Function to toggle description view
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Function to handle the download of the Rive file
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = item.riveData.riveFile;
    link.download = item.title + ".riv"; // Set the download attribute with a filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Increment the download count
    setDownloadCount(downloads + 1);
    // Update the download count in the data (you might want to persist this change in a real application)
    data[itemIndex].downloads += 1;
  };

  const maxDescriptionLength = 100; // Adjust this value as needed
  const isDescriptionLong = item.description.length > maxDescriptionLength;

  return (
    <div className="bg-inherit text-gray-100">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-4">
        <div className="lg:w-2/3">
          <div className=" border border-slate-800 rounded-lg overflow-hidden shadow-lg">
            <div className="aspect-w-16 aspect-h-9">
              <RiveComponent />
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 space-y-6">
          <div className="bg-slate-900 border border-slate-800 rounded-lg p-6 shadow-lg">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-2xl font-bold">{item.title}</h2>
              <button className="p-2 hover:bg-gray-700 rounded-full transition-colors">
                <Copy size={20} />
              </button>
            </div>
            <p className="text-gray-300 mb-4">
              {showFullDescription || !isDescriptionLong
                ? item.description
                : item.description.substring(0, maxDescriptionLength) + "..."}
              {isDescriptionLong && (
                <button
                  className="text-primary-500 hover:underline ml-2"
                  onClick={toggleDescription}
                >
                  {showFullDescription ? "Show less" : "Show more"}
                </button>
              )}
            </p>
            <div className="mt-2 flex justify-between text-sm text-gray-400">
              <span>{item.license}</span>
              <span>
                {item.uploadDate} •{" "}
                {item.isDownloadable ? "Downloadable" : "View Only"}
              </span>
            </div>
          </div>

          {/* <div className="bg-slate-900 border border-slate-800 rounded-lg p-6 shadow-lg">
            <div className="flex items-center justify-between">
              <span className="text-lg">Likes</span>
              <div className="flex items-center bg-gray-700 px-3 py-1 rounded-full">
                <Heart size={18} className="text-primary-500 mr-2" />
                <span>{item.likes}</span>
              </div>
            </div>
          </div> */}

          {item.isDownloadable && (
            <div className="bg-slate-900 border border-slate-800 rounded-lg p-6 shadow-lg">
              <button
                className="w-full bg-primary-500 text-white rounded-lg flex items-center justify-center hover:bg-primary-600 transition-colors"
                onClick={handleDownload}
              >
                <Download size={20} className="mr-2" />
                <span>Download File</span>
              </button>
              <p className="mt-4 text-center text-gray-400">
                {downloads} {downloads === 1 ? "download" : "downloads"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    // <div className="w-full text-white px-36">
    //   <main className="flex flex-col lg:flex-row gap-6">
    //     <div className="lg:w-3/4">
    //       <div
    //         className="bg-slate-900 border border-slate-800 rounded-lg aspect-video flex items-center justify-center"
    //         style={{ aspectRatio: 2 }}
    //       >
    //         <div className="w-full h-full">
    //           <RiveComponent />
    //         </div>
    //       </div>
    //       {/* <div className="mt-4 bg-slate-900 border border-slate-800 rounded-lg p-4">
    //         <div className="flex space-x-4">
    //           <button className="px-4 py-2 bg-gray-700 rounded-md">
    //             Comments
    //           </button>
    //           <button className="px-4 py-2">Remixes</button>
    //         </div>
    //         <div className="mt-4">
    //           <button className="w-full px-4 py-2 bg-blue-600 rounded-md">
    //             Log in
    //           </button>
    //           <p className="text-center mt-2 text-gray-400">
    //             or sign up to comment
    //           </p>
    //         </div>
    //       </div> */}
    //     </div>

    //     <div className="lg:w-1/4 space-y-4">
    //       <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
    //         <div className="flex justify-between items-start mb-2">
    //           <h2 className="text-xl font-bold">{item.title}</h2>
    //           <button className="p-1">
    //             <Copy size={16} />
    //           </button>
    //         </div>
    //         <p className="mt-2 text-sm text-gray-400">
    //           {showFullDescription || !isDescriptionLong
    //             ? item.description
    //             : item.description.substring(0, maxDescriptionLength) + "..."}
    //           {isDescriptionLong && (
    //             <button className="text-blue-400" onClick={toggleDescription}>
    //               {showFullDescription ? "\u00A0 Show less" : "Show more"}
    //             </button>
    //           )}
    //         </p>
    //         <div className="mt-2 flex justify-between text-sm text-gray-400">
    //           <span>{item.license}</span>
    //           <span>
    //             {item.uploadDate} •{" "}
    //             {item.isDownloadable ? "Downloadable" : "Viewing"}
    //           </span>
    //         </div>
    //       </div>

    //       <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
    //         <div className="flex items-center justify-between">
    //           <span>Likes</span>
    //           <div className="flex items-center">
    //             <span className="mx-2">{item.likes}</span>
    //             <Heart size={16} />
    //           </div>
    //         </div>
    //       </div>

    //       {item.isDownloadable && (
    //         <div className="bg-slate-900 border border-slate-800 rounded-lg p-4">
    //           <button
    //             className="w-full text-left flex items-center justify-between"
    //             onClick={handleDownload}
    //           >
    //             <span>Download File</span>
    //             <Download size={16} />
    //           </button>
    //           <p className="mt-2 text-sm text-gray-400">
    //             {downloads} {downloads === 1 ? "download" : "downloads"}
    //           </p>
    //         </div>
    //       )}
    //     </div>
    //   </main>
    // </div>
  );
};

export default PreviewPage;
