// src/components/Gallery.js

import React from "react";
import GalleryItem from "../components/GalleryItem";

const Gallery = ({ items }) => {
  return (
    <>
      <div className="w-full container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {items.map((item, index) => (
          <GalleryItem key={item.id || index} item={item} index={index} />
        ))}
      </div>
    </>
  );
};

export default Gallery;
