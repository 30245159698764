import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="bg-inherit text-gray-400 py-2 px-4 text-sm">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center space-x-4">
              <span>© 2024 Rive Hub.</span>
              <a href="#" className="hover:text-gray-200">
                Terms
              </a>
              <a href="#" className="hover:text-gray-200">
                Privacy
              </a>
            </div>
            <div className="flex items-center space-x-4">
              {/* <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fab fa-youtube"></i>
              </a> */}
              {/* <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fab fa-twitter"></i>
              </a> */}
              {/* <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fab fa-instagram"></i>
              </a> */}
              <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fab fa-github"></i>
              </a>
              {/* <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fab fa-dribbble"></i>
              </a> */}
              <a href="#" className="text-gray-400 hover:text-gray-200">
                <i className="fas fa-gamepad"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
