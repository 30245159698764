import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Search, Menu, X } from "lucide-react";

const Header = ({ searchTerm, setSearchTerm }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? "auto" : "hidden";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`sticky top-0 z-10 text-gray-300 py-2 bg-inherit ${
        hasShadow ? "shadow-md" : ""
      } transition-shadow`}
    >
      <div className="container mx-auto px-6 sm:px-4 px-4">
        <div className="flex flex-wrap sm:flex-nowrap items-center justify-between py-3 px-4 sm:px-0">
          <div className="flex items-center justify-between w-full sm:w-auto">
            <Link
              to="/"
              className="text-xl font-semibold whitespace-nowrap mr-4 text-white hover:text-gray-300 transition-colors"
            >
              Rive Hub
            </Link>
            {location.pathname === "/" && (
              <button
                className="sm:hidden text-gray-400 hover:text-white transition-colors"
                onClick={toggleMenu}
              >
                {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
              </button>
            )}
          </div>

          {location.pathname === "/" && (
            <div
              className={`w-full sm:flex items-center space-y-3 sm:space-y-0 sm:space-x-4 ${
                isMenuOpen ? "block mt-4" : "hidden sm:flex"
              }`}
            >
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search the Community"
                  className="w-full bg-gray-800 rounded-full py-2 px-6 pr-10 focus:outline-none focus:ring-1 focus:ring-gray-700 text-sm"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search
                  className="absolute right-3 top-2.5 text-gray-500"
                  size={18}
                />
              </div>
              {/* <NavButton>Featured</NavButton>
              <NavButton>Latest</NavButton> */}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

const NavButton = ({ children }) => (
  <button className="px-6 py-2 bg-gray-800 rounded-full text-sm hover:bg-gray-700 transition-colors w-full sm:w-auto flex-shrink-0">
    {children}
  </button>
);

export default Header;
