import React from "react";
import { useNavigate } from "react-router-dom";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { Search, Heart, User, Menu, X, Download } from "lucide-react";

const GalleryItem = ({ item, index }) => {
  const navigate = useNavigate();

  const { RiveComponent } = useRive({
    src: item.riveData.riveFile, // Dynamic Rive file source from JSON data
    stateMachines: item.riveData.stateName, // Adjust according to your Rive file
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    autoplay: false,
  });

  const handleClick = () => {
    navigate(`/preview/${item.id}`);
  };

  return (
    <>
      <div
        className="bg-slate-900 border border-slate-800 rounded-xl overflow-hidden cursor-pointer transition-all duration-300 hover:shadow-lg hover:shadow-gray-700/30"
        onClick={handleClick}
      >
        <div className="aspect-w-16 aspect-h-9 bg-slate-950">
          <RiveComponent />
        </div>
        <div className="p-4 flex items-center justify-between">
          <h3 className="font-medium text-gray-100 truncate flex-1 mr-4">
            {item.title}
          </h3>
          <div className="flex items-center space-x-4 text-gray-400 text-sm flex-shrink-0">
            <div className="flex items-center">
              <Heart size={14} className="mr-1 text-gray-500" />
              <span>{item.likes}</span>
            </div>
            <div className="flex items-center">
              <Download size={14} className="mr-1 text-gray-500" />
              <span>{item.downloads}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryItem;
