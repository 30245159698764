import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Gallery from "./app/Gallery";
import PreviewPage from "./app/PreviewPage";
import data from "./app/data.json";
import { Search, Heart, User, Menu, X } from "lucide-react";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data);
  }, []);

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  return (
    <Router>
      <div className="flex flex-col min-h-screen  bg-black">
        <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <div className="flex-grow flex justify-center">
          <Routes>
            <Route
              path="/"
              element={
                <main className="flex-grow mx-auto p-4">
                  <Gallery items={filteredItems} />
                </main>
              }
            />
            <Route
              path="/preview/:id"
              element={
                <main className="flex-grow mx-auto p-4">
                  <PreviewPage />
                </main>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
